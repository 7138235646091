export const createArtistSlug = (artistName) => {
    let artist = artistName.replace(/[^a-zA-Z0-9 ]/g, '');
    return artist?.toLowerCase().replace(/ /g, '-');
}

export function getDynamicUtmSource() {
    const referrer = document.referrer;
    if (!referrer) return 'organic';

    const referrerDomain = new URL(referrer).hostname;
    const domainParts = referrerDomain.split('.');
    const mainDomain = domainParts[domainParts.length - 2]; // Get the main domain name

    return mainDomain?.toLowerCase();
}

const BIOS = {
'453523728' : `<p>While Kuhad has long been a household name in his native country (he became one of Spotify India's most-streamed artists when the service launched there recently, and his latest EP, 'cold/mess,' debuted at #1), 2019 proved to be his breakout year in North America, with a starmaking turn at SXSW and a cross-country headline run that culminated with three sold out shows in NYC. US press was quick to take notice of what millions of listeners around the world already knew, and rave reviews followed Kuhad everywhere he went: Austin Monthly dubbed him “a certifiable international sensation,” US Weekly praised his music as “beautifully haunting,” and Brooklyn Vegan celebrated his "angelic vocals".</p>
<p>With captivating live performances that at once call to mind the delicate beauty of Sufjan Stevens and the ferocious fingerpicking of The Tallest Man On Earth, it’s easy to see Kuhad’s rise to global stardom as a given, but the truth is that he was actually something of a late bloomer. After an unsuccessful attempt at lessons as a youngster, he enrolled in a guitar class in high school, but that, too, ended in failure. Those setbacks couldn’t dampen Kuhad’s love of music, though, and he consumed as much of it as he possibly could growing up in the small city of Jaipur. Because the internet didn’t arrive in his area until the late 90’s, Kuhad’s childhood listening diet consisted primarily of the Indian pop and Bollywood soundtracks that filled the local radio dial, as well as his parents’ CD collection, which contained only limited Western music.
</p><p>"After high school, I moved to New York to attend NYU, and that's when I discovered Elliott Smith" explains Kuhad. "His music changed everything for me. It was all I listened to my entire freshman year. After that, I started listening to Bob Dylan and Woody Guthrie along with newer artists like Laura Marling and Fleet Foxes. It inspired me to get serious about the guitar and begin writing my own songs."
</p><p>Liberated by the limitless possibility he discovered in New York, Kuhad decided to take a leap of faith and pursue music full time upon his return to India. A pair of early EPs (one in English, one in Hindi) put him on the map, and his full-length debut, ‘In Tokens and Charms,’ was an instant hit. The record earned Kuhad an MTV Europe Music Award, Indie Album of the Year honors from iTunes, and the title of Best Pop Artist at the Radio City Freedom Awards, and the album’s opening track, “Oh Love,” captured first place in the prestigious International Songwriting Competition, which had previously helped launch artists like Gotye and Passenger to global audiences. Soon, Kuhad was selling out auditorium and amphitheater dates across India, landing arena support slots with the likes of Alt-J and Mike Posner, and traveling the world for headline and festival performances in the US, Australia, Singapore, Canada, and France. Nike selected him to join their #BleedBlue campaign, Converse invited him to record in Rio de Janeiro as part of their Rubber Tracks series, and, when he landed in Austin for the first time, NPR selected Kuhad as an artist to watch among the thousands slated to showcase at SXSW.
</p><p>Prateek Kuhad is currently on his longest and largest international tour, spanning 30+ dates in 8 countries all through October to December.
</p>`,

'1388609753' : `<p>Anuv Jain is best described as “a singer-songwriter for every human experience”. The 28-year-old from Punjab, India went from being an online sensation to becoming one of the biggest indie music stars in the country.
</p><p>Anuv had his breakout moment when he released the studio version of ‘Baarishein’, which has allowed him to enjoy a cult following who have popularised his songs on every acoustic playlist. His discography bridges the space between honest lyrics and evocative melodies, which is why his Spotify boasts over 4 million monthly listeners with hit songs like Gul, Alag Aasmaan, and Mishri among fan favorites.
</p><p>His first All-India tour in October 2021 sold out across all 10 cities — a significant feat for a young independent artist during the pandemic.
</p><p>In 2022, he drew an awe-inspiring crowd of approximately 20,000 people to the Delhi Beat Street Festival, a crowd that sang back all of his songs lyrics. Anuv was also one of the select few artists to feature on the lineup leading to international singer Post Malone’s performance in India.
</p>`,

'1511245356' : `<p>Akshath Acharya is a sensational 23-year-old talent hailing from Mumbai who's journey began when his talent went viral with over 120K followers on the internet, propelling him to explore his abilities as a songwriter and producer. This young artist has taken the music creator realm by storm, amassing 50 million+ views on social media. With a string of releases this year, Akshath is carving his niche in the Indie-pop domain and achieving extraordinary milestones. He firmly believes that the Indian Indie-pop space is yearning for a missing ingredient, one he is here to introduce through heartfelt songwriting and cutting-edge music production. Besides 'Noor' acquiring over 2M streams, his single's 'Jadoo' and 'Kya Ho Gaya' also garnered 1M+ streams individually across platforms, with 'Jadoo' charting at #6 on Spotify Viral-100 India and the latter managing to enter India radio air check at #10. In 2023, Akshath is all set to release more of his original music & break through in the Indie-pop space.
</p>`,

'1360012794' : `<p>Nikhil D'Souza (born 21 November 1981) is a singer, songwriter, and guitarist from Mumbai, India, and is now signed to East West Records of Warner Music UK. Nikhil D'Souza was born and brought up in Mumbai and majored in Geology from St. Xavier's College, Mumbai but returned to his musical roots after an encounter with a friend. Nikhil's genre and musical style can be loosely defined as eclectic acoustic guitar-based pop. His songs have a full melodic quality to them due to his use of alternative tunings (favoured by such artists as Nick Drake and Jeff Buckley). Nikhil's major musical influences are Sting and Jeff Buckley. He was the South Asia Soloist Winner at SUTASI '09. Nikhil has released four songs with East West Records, a label owned by Warner Music UK. These include Silver and Gold and Beautiful Mind. Rolling Stone Music Magazine in India has favourably featured Nikhil's music several times: Neha Sharma from Rolling Stone said his "strength as a songwriter is well established in his numerous gripping compositions. Most of his songs shiver with this arresting melancholy as his falsetto glides over twangy guitar riffs.” India Today also included Nikhil as part of their "Faces of the Future," and he has featured on MTV's COKE Studio series and Sofar Sounds.
</p>`,

'1441039241' : `<p>Kabeer Kathpalia aka OAFF produces and composes songs that bridge the gap between mainstream Bollywood music and feel-good pop. His 2022 debut Bollywood OST (with co-producer Savera) Gehraiyaan (Original Motion Picture Soundtrack), broke records across, with colossal 250M+ streams on the audio album, and 200M+ views across YouTube. He has garnered top accolades for his compositions; his single Duur with Kamakshi Khanna was featured in Netflix Original’s Fame Game starring Madhuri Dixit. OAFF’s single So Good with Kayan was promoted on Spotify’s Radar India. Born in Ahmedabad but currently working in Mumbai, Kabeer Kathpalia studied physics and mathematics at university. After graduation, however, he chose to pursue his passion for music (astute listeners will recognise the minimalism of his songwriting and production style, which is almost mathematical in its precision and symmetry).
</p><p>With his deft touch, Kathpalia has woven over 500 musical masterpieces for a wide variety of international brand campaigns for the likes of H&M, Airbnb, Volkswagen, and the official Olympics Channel. He composed the title theme for the Amazon Prime Video's Emmy-nominated show Inside Edge and created an array of immersive audio installations in Asia, Europe and the US.
</p><p>In 2022, OAFF teamed up with Meta to create #1MinMusic track, Tu Kahaan (1 Min Music) crossing 100k reels in a month. He kicked off 2023 on a high note with Udja and Feel You Now.
</p>`,

'526977614' : `<p>Peter Cat Recording Co. was formed in 2010. Imagined as a radio station from the near future or a vinyl from the distant past, their true identity transcends any single genre or classification. The music speaks for itself, and each song lives in its own universe.
</p><p>Having built a loyal fan base worldwide with their critically acclaimed albums, Portrait of a Time and Bismillah, they have performed across multiple continents, with groundbreaking sold-out tours in North America, U.K., Europe, Australia & India.
</p><p>2024 will see the release of their forthcoming album BETA, a painting of the living and the lived.
</p><p>PCRC is Suryakant Sawhney on vocals and guitars, Karan Singh on drums, Dhruv Bhola on bass and samples, Rohit Gupta on keys and trumpet, and Kartik Sundareshan on guitars and trumpet.
</p>`,

'1202624284': `<p>When Chai Met Toast is a pop band from India. The quartet came into being with their debut EP 'Joy of Little Things' in 2017. Over the next year, they crafted their sophomore offering 'Believe', establishing them as genre pioneers in the country. The band's first full length album 'When We Feel Young' (2021) propelled their worldwide listener base. WCMT's sound can be best described as a mix of indie folk and alternative rock. Their live performances have attracted audiences globally, with performances headlining numerous Indian festivals, sets at the Singapore Grand Prix 2022, Expo 2020 Dubai, and sold out tours across the UK and US. The band's latest is their third EP 'LYTS', recorded in London with producer Richard Wilkinson.
</p><p>"We are exploring new territories with our music now, and in the seven years we've been a band together, we've sort of come to learn a lot about each other. At the heart of it, we're four brown boys trying to spread our little personal stories to the world with our music. The LYTS EP talks about love and heartbreak."
</p><p>When Chai Met Toast consists of Ashwin Gopakumar, Achyuth Jaigopal, Palee Francis and Pai Sailesh, who met in the music circles of Kerala.
</p>`,

'1465828453': `<p>Born in the foothills of Himalaya Apurva Tamang is a singer/songwriter based in Mirik, Darjeeling,India with 25 millions streams in Youtube &Spotify collectively with listeners and viewers in more than 125 countries across the globe.</p>
<p>Apurva burst into the Entertainment Industry as a child sensation from Darjeeling Hills after gaining fame as a singer in ZeeTv’s Sa Ra Ga Ma PaLil Champs 2009.He has even been part of Indian Idol Junior (2013) andIndian Idol Senior (2018).
</p><p>Apurva had his vocal training in Hindustani Classical Style from Prayag Sangeet Samiti In Allahabad and is currently pursuing Masters in Music.
</p><p>"The most promising Nepali-origin Indian musicians to break into Nepal’s music scene in the last two years." - KATHMANDU POST
</p><p>"With every new original composition, he proves his credibility as not only a songwriter but also a fantastic composer and singer." - A HUMMING HEART
</p>`,

'1356034033': `<p>Hailing from Amdavad, GJ, Dhanji is building a base at the forefront of the Indian avant-garde. Shaking things up with his untouchable style and the incomparable jazz about him. With an impressive discography of seven mixtapes already under his belt, Dhanji is single-handedly shaping the culture one drop at a time. Inspired by legends like Jay-Z, Nas, and Wu-Tang Clan, he remains a dedicated student of the game, paying homage to his influences in every track. Unapologetically authentic and refusing to conform to mainstream trends, the rapper stands tall as a true pioneer of Indian hip-hop.</p>`,
'1523224850': `<p>Jessica Sandida Lyngdoh also known as 'Jessie Lyngdoh' (born on 23rd June 1994) is a Singer-Songwriter hailing from the beloved hills of Meghalaya, Shillong. Besides Music she is pursuing her artistic attention in the media field as a photographer, videographer/editor and handles business marketing on various social media pages. On her journey in music she has produced more than 50 songs in the last 14 years. Jessie Lyngdoh looks further into creating an influence using her music in contributing to the community in the future.</p>`,

}

export const getArtistBio = (artistId) => {
    if (BIOS[artistId]) {
        return BIOS[artistId]
    }
    return ""
}
