import React, { useState, useEffect } from 'react';
import { IoMdClose } from 'react-icons/io';
import { FcGoogle } from 'react-icons/fc';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from "firebase/auth";
import { generateUsername } from "friendly-username-generator";


const Modal = ({ onClose, artistId, artistName }) => {
  const [step, setStep] = useState(1);
  const [gender, setGender] = useState('');
  const [city, setCity] = useState('');
  const [age, setAge] = useState('');
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAlreadyMember, setIsAlreadyMember] = useState(false);

  useEffect(() => {
    const joinedArtists = JSON.parse(localStorage.getItem("joinedArtists") || "[]");
    setIsAlreadyMember(joinedArtists.includes(artistId));
  }, [artistId]);

  const handleGoogleSignUp = async () => {
    setLoading(true);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log(result.user);

      if (result.user) {
        try {
          const old_username = localStorage.getItem("username");

          let savedUsername = localStorage.getItem("username");

          if (typeof savedUsername !== "string" || savedUsername.length === 0) {
            savedUsername = generateUsername();
            localStorage.setItem("username", savedUsername);
          }
          const body = {
            old_username:
              old_username?.length > 0 ? old_username : savedUsername,
            username: result.user.displayName,
          };

          let checkIfReferralUsed = localStorage.getItem("referralCodeUsed");
          if (checkIfReferralUsed === null) {
            let ref = localStorage.getItem("ref");
            console.log("Referral code", ref);
            const body = {
              referralCode: ref,
              referreduser: result.user.email,
            };
            fetch(`https://cruncher.asset.money/misc/referral`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            })
              .then((response) => {
                console.log(response);
                localStorage.setItem("referralCodeUsed", "true");
              })
              .catch((err) => {
                console.log(err?.message);
              });
          }

          result.user.getIdToken().then(async (token) => {
            setAccessToken(token);
            localStorage.setItem("loggedIn", "true");
            
            const joinedArtists = JSON.parse(localStorage.getItem("joinedArtists") || "[]");
            if (!joinedArtists.includes(artistId)) {
              joinedArtists.push(artistId);
              localStorage.setItem("joinedArtists", JSON.stringify(joinedArtists));
            }
            
            window.location.href = `https://muzify.com/quiz/${artistId}?returnUrl=https://muzify.com/artist/${artistId}`;
          });
        } catch (e) {
          console.log("error", e);
        }
      }
    } catch (error) {
      console.error("Google Sign-In Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (step < 3) setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://cruncher.asset.money/artist/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          artistId,
          artistName,
          city,
          gender,
          age: parseInt(age, 10),
        }),
      });

      if (!response.ok) throw new Error('Network response was not ok');
      
      const data = await response.json();
      console.log('Submission successful:', data);
      
      const isLoggedIn = localStorage.getItem("loggedIn") === "true";
      if (isLoggedIn) {
        const joinedArtists = JSON.parse(localStorage.getItem("joinedArtists") || "[]");
        if (!joinedArtists.includes(artistId)) {
          joinedArtists.push(artistId);
          localStorage.setItem("joinedArtists", JSON.stringify(joinedArtists));
        }
        window.location.href = `https://muzify.com/quiz/${artistId}?returnUrl=https://muzify.com/artist/${artistId}`;
      } else {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const stepIndicators = (
    <div className="flex justify-between mb-8">
      {[1, 2, 3].map((num) => (
        <div
          key={num}
          className={`w-8 h-8 rounded-full flex items-center justify-center ${
            num === step ? 'bg-[rgba(239,209,101,1)] text-black' : 'bg-gray-700 text-white'
          }`}
        >
          {num}
        </div>
      ))}
    </div>
  );

  if (isSubmitted) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
        <div className="bg-[#0D1628] p-8 rounded-lg w-96 text-center relative">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-white hover:text-[rgba(239,209,101,1)] transition-colors"
          >
            <IoMdClose size={24} />
          </button>
          <h2 className="text-3xl font-bold mb-4 text-[rgba(239,209,101,1)]">Congratulations!</h2>
          <p className="mb-6 text-white">Welcome to {artistName}'s Fan Club.</p>
          <button
            onClick={handleGoogleSignUp}
            className="flex items-center justify-center gap-2 w-full bg-[rgba(239,209,101,1)] text-black px-6 py-3 rounded-full font-semibold hover:bg-[rgba(239,209,101,0.8)] transition-colors mb-4"
          >
            <FcGoogle size={24} />
            Play Now
          </button>
          {/* <a
            href={`https://muzify.com/quiz/${artistId}`}
            className="inline-block bg-[rgba(239,209,101,1)] text-black px-6 py-3 rounded-full font-semibold hover:bg-[rgba(239,209,101,0.8)] transition-colors"
          >
            Play Now
          </a> */}
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-[#0D1628] p-8 rounded-lg w-96 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-[rgba(239,209,101,1)] transition-colors"
        >
          <IoMdClose size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-6 text-[rgba(239,209,101,1)] text-center">
          {isAlreadyMember ? "Already a Super-Fan!" : "Become a Super-Fan"}
        </h2>

        {isAlreadyMember ? (
          <div className="text-center">
            <p className="text-white mb-6">You're already a member of {artistName}'s Fan Club!</p>
            <button
              onClick={() => window.location.href = `https://muzify.com/quiz/${artistId}?returnUrl=https://muzify.com/artist/${artistId}`}
              className="bg-[rgba(239,209,101,1)] text-black px-6 py-3 rounded-full font-semibold hover:bg-[rgba(239,209,101,0.8)] transition-colors w-full"
            >
              Play Now
            </button>
          </div>
        ) : (
          <>
            {stepIndicators}

            {step === 1 && (
              <div>
                <h3 className="text-xl mb-4 text-white">Select your gender</h3>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className="w-full p-3 border rounded bg-[#1E2228] text-white border-gray-600"
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            )}

            {step === 2 && (
              <div>
                <h3 className="text-xl mb-4 text-white">Enter your city</h3>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="w-full p-3 border rounded bg-[#1E2228] text-white border-gray-600"
                  placeholder="Your city"
                />
              </div>
            )}

            {step === 3 && (
              <div>
                <h3 className="text-xl mb-4 text-white">Enter your age</h3>
                <input
                  type="number"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  className="w-full p-3 border rounded bg-[#1E2228] text-white border-gray-600"
                  placeholder="Age"
                />
              </div>
            )}

            <div className="flex justify-between mt-8">
              {step > 1 && (
                <button
                  onClick={handlePrevious}
                  className="bg-gray-700 text-white px-6 py-2 rounded-full hover:bg-gray-600 transition-colors"
                >
                  Previous
                </button>
              )}
              {step < 3 ? (
                <button
                  onClick={handleNext}
                  className="bg-[rgba(239,209,101,1)] text-black px-6 py-2 rounded-full hover:bg-[rgba(239,209,101,0.8)] transition-colors ml-auto"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="bg-[rgba(239,209,101,1)] text-black px-6 py-2 rounded-full hover:bg-[rgba(239,209,101,0.8)] transition-colors ml-auto"
                >
                  Submit
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
